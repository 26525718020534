import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects `}<p style={{
        fontWeight: 200,
        fontSize: 14,
        marginLeft: 1
      }}>{`*`}{`live demos coming soon!`}</p></h2>
    <ProjectCard title="AI Maze Solver" link="#" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Implemented hill climbing, shortest path first, A*, and genetic algorithm
  search algorithms in Python to solve mazes, identify and create complex mazes,
  and analyze optimal heuristics.
    </ProjectCard>
    <ProjectCard title="Flight Booking System" link="#" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Created an airline ticket booking service using Java Server Pages (JSP)
  combined with HTML and CSS styling. Designed a MySQL database that holds data
  about flights and runs queries based on customers and airline manager
  specifications. Collaborated with 4 group members using GitHub for tracking
  changes.
    </ProjectCard>
    <ProjectCard title="URL Shortner" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A URL shortner built with React, Node.js, Express, and MongoDB
    </ProjectCard>
    <ProjectCard title="Chat Application built with Socket.io" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This is a chat application built with React, socket.io, node.js
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      