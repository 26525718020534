import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExperienceCard = makeShortcode("ExperienceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Professional Experience`}</h2>
    <ExperienceCard title="Fidelity Investments" link="#" imgSrc="fidelity" timeline="Summer 2020" jobTitle="Software Development Intern" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ExperienceCard">
  <li>
    Implemented an incident ticket classifier utilizing <b>machine</b> <b>learning</b>{" "}
    algorithms in <b>Python</b> that automates the assignment of incoming
    ServiceNow incidents to the most appropriate group. Optimized turnaround
    time for the incident and overall impact of incidents by 30%.
  </li>
  <li>
    As a full stack engineer, spearheaded the design and development of an
    intuitive front-end UI using <b>Vue.JS</b>, <b>Express</b>, <b>Redis</b>,{" "}
    <b>Node.JS</b>, <b>HTML</b>, and <b>CSS</b> for users to easily query
    applications for dependency mapping and specific application information
    curated from different company resources.
  </li>
  <li>
    Created an application dependency mapping tool to efficiently triage
    component failures, using <b>Python</b> scripting to collect data from
    uDeploy, ServiceNow, Splunk, and several company APIs to establish and
    document connections between 5,000 applications and their corresponding
    dependencies.
  </li>
  <li>
    Used <b>Scrum</b> and <b>Agile</b> methodology to maximize workflow and
    ensure client satisfaction.
  </li>
    </ExperienceCard>
    <ExperienceCard title="ABJ Drones" imgSrc="abjDrones" jobTitle="Data Analyst & I.T. Specialist" timeline="2017-2020" link="#" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ExperienceCard">
      <li>
  Implemented a defect detector utilizing <b>TensorFlow</b> in <b>Python</b> that automates the analysis of wind turbine images by accurately detecting and classifying issues with the turbine. Reduced turnaround time of the project to the client by 50%.
      </li>
      <li>
  Executed post-processing of wind turbine inspections. Managed image data set, mass image editing, and analysis of inspection images for clients.
      </li>
      <li>
  Consolidated all Zoho Suite functions. Customized the user interface to be suitable for pilots, sales team, and senior management.
      </li>
      <li>
  Improved service for ABJ Drones and its e-learning platform. Increased service ratings from customers by 60%.
      </li>
    </ExperienceCard>
    <ExperienceCard title="0-Downtime" timeline="Summer 2019" jobTitle="Software Development Intern" link="#" imgSrc="downtime" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ExperienceCard">
      <li>
  Executed <b>AWS</b> <b>Lambda</b> and <b>Serverless</b> <b>Framework</b> using <b>Python</b> to create multiple functions using different <b>AWS</b> services.
      </li>  <li>Developed modules for a recruiting company’s website to add functionality for video resume upload and transcription.</li>
    </ExperienceCard>
    <ExperienceCard title="Compunnel Digital" timeline="2017-2019" jobTitle="Software Development Intern" imgSrc="compunnel" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" sx={{
      background: 'white'
    }} mdxType="ExperienceCard">
      <li>
  Optimized final deliverable for a domestic healthcare company by utilizing <b>machine</b> <b>learning</b>  and <b>python</b> to match a new patient with an existing one based on previous patient history to accurately determine a strong future plan for the patient.
      </li><li>Strategically programmed a resume filtration system that utilized <b>machine</b> <b>learning</b> and <b>python</b> to sift through incoming resumes for information and input them into the company database under the job listing their skills aligned to.</li>
      <li>Utilized <b>Java Spring</b> framework to improve event handling for their company website.</li>
    </ExperienceCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      